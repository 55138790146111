import React, { useEffect, useState } from 'react';
import axiosService  from '../../services/axios.service';
import Loader from '../Common/Loader/Loader';
import PropTypes from 'prop-types';
import Search from '../Common/Search/Search';
import Pagination from '../Common/Pagination/Pagination';
import { getOffsetFromPageQuery } from '../../helpers/pagination.helper';
import QueryParamsHelper from '../../helpers/query-params.helper';
import hasWindow from '../../constants/has-window.const';
import { lessonsLimit } from '../../constants/pagination-limits.const';
import SelectedOptions from '../Common/SelectedOptions/SelectedOptions';
import Filters from '../Common/Filters/Filters';
import Sidebar from '../Common/Sidebar/Sidebar';
import { isNotEmptyArray } from '../../helpers/arrays.helper';
import { getSelectedOptions, transformAPIDataToAttributes } from '../../helpers/attributes.helper';
import { useLocation } from '@reach/router';
import { handleBadRequestError } from '../../helpers/error-handler.helper';
import { searchOptions } from '../../constants/search-options.const';
import ProductsList from '../Common/product-list/ProductsList';
import { productTypes } from '../../constants/product-types.const';
import LessonsList from './LessonsList';
import { lessonsLayoutStorageKey, productsLayoutKeys } from '../../constants/product-layouts.const';
import SearchLayoutSelect from '../Common/SearchLayoutSelect/SearchLayoutSelect';
import ListConfigWrapper from '../Common/ListConfigWrapper';
import StickySearchWrapper from '../Common/StickySearchWrapper/StickySearchWrapper';

export const DynamicLessonsList = ({ isPreviewMode = false }) => {
  const path = `/lessons`;
  const apiUrl = `${process.env.API_URL}/v2/lessons`;
  const limit = lessonsLimit;
  const [itemsLayout, setItemsLayout] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [isLessonsLoaded, setIsLessonsLoaded] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isQueryError, setIsQueryError] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [paramsState, setParamsState] = useState(null);
  const isClosedFilterNumber = 1; // 4;

  if (hasWindow) {
    const deps = isPreviewMode ? [] : [useLocation()];

    useEffect(() => {
      if (limit) {
        setParamsState(getParamsStateFromUrlQuery());
      }
    }, deps);
  }

  useEffect(() => {
    if (paramsState) {
      loadLessonsAndAttributes(getParams());
    }
  }, [paramsState]);

  function loadLessonsAndAttributes(params) {
    setIsLoader(true);
    setIsQueryError(false);
    Promise.all([
      getLessons(params),
      getAttributes(params)
    ])
    .catch(error => handleBadRequestError(error, onFieldError))
    .finally(() => {
      setIsLoader(false);
      setIsLessonsLoaded(true);
    });
  }

  function onFieldError(field) {
    if (field && field.property && field.property === 'query') {
      setIsQueryError(true);
    }
  }

  async function getLessons(params) {
    return await axiosService.get(apiUrl, { params })
      .then((res) =>{
        setLessons(res.data.data);
        setTotalCount(res.data.meta.totalCount);
      });
  }

  async function getAttributes(params) {
    return await axiosService.get(`${apiUrl}/attributes`, { params })
      .then((res) => {
        setAttributes(transformAPIDataToAttributes(res.data.data, paramsState));
      });
  }

  function getParamsStateFromUrlQuery() {
    const state = {
      offset: getOffsetFromPageQuery(limit),
      query: QueryParamsHelper.getOne('query'),
    };

    const keys = QueryParamsHelper.getAllUniqueKeys();
    keys.forEach(key => {
      if (key !== 'query' && key !== 'page') {
        state[key] = QueryParamsHelper.getAll(key) || [];
      }
    });

    return state;
  }

  function getParams() {
    let params = {
      limit,
      offset: paramsState ? paramsState.offset : 0,
    };

    if (paramsState) {
      const APIParamsFromState = QueryParamsHelper.transformParamsStateToAPIParams(paramsState);
      params = APIParamsFromState ? { ...APIParamsFromState, ...params } : params;
    }

    return params;
  }

  function getMainBlockColClass() {
    return isNotEmptyArray(attributes) ? 'col-md-8 col-lg-9' : '';
  }

  function onLayoutChange(layout) {
    setItemsLayout(layout);
  }

  return (
    <div className={`container`}>
      <StickySearchWrapper>
        <Search
          isPreviewMode={isPreviewMode}
          inputQuery={paramsState ? paramsState.query : ''}
          hasError={isQueryError}
          defaultSearchOption={searchOptions[1]}
          className={`mb-2`}
        />
      </StickySearchWrapper>
      {isLoader && (<Loader hasBackground={true} isFixed={true}/>)}
      <div className="row">
        {
          isNotEmptyArray(attributes) && (
            <div className="col col-12 col-md-4 col-lg-3 mb-5">
              <Sidebar title={`Filters`}>
                {
                  attributes.map((attribute, i) => (
                    <Filters
                      key={i}
                      title={attribute.title}
                      isTopLevelFilters={attribute.isTopLevelFilters}
                      filters={attribute.options}
                      isPreviewMode={isPreviewMode}
                      initialIsOpenState={i < isClosedFilterNumber}
                    />
                  ))
                }
              </Sidebar>
            </div>
          )
        }
        <div className={`col col-12 ${getMainBlockColClass()}`}>
          <ListConfigWrapper>
            <SelectedOptions
              selectedOptions={getSelectedOptions(paramsState, attributes)}
              className={`flex-grow-1 mr-sm-2`}
            />

            <SearchLayoutSelect
              storageKey={lessonsLayoutStorageKey}
              onChange={onLayoutChange}
              className={`mb-2 ml-auto flex-shrink-0`}
            />
          </ListConfigWrapper>

          {
            isLessonsLoaded && (
              <React.Fragment>
                <div className={!isNotEmptyArray(lessons) ? 'd-none' : ''}>
                  {
                    itemsLayout === productsLayoutKeys.grid && (
                      <ProductsList
                        products={lessons}
                        productType={productTypes.lesson}
                        productPath={`/lessons`}
                        isFilter={false}
                        className={`px-0 px-lg-3`}
                        colClassName={'col col-6 col-sm-4 col-xl-3'}
                      />
                    )
                  }

                  {
                    itemsLayout === productsLayoutKeys.list && (
                      <LessonsList
                        lessons={lessons}
                        className={`px-0 px-lg-3`}
                      />
                    )
                  }

                  <div className="mt-auto">
                    <Pagination
                      totalCount={totalCount}
                      offset={paramsState ? paramsState.offset : 0}
                      limit={limit}
                      path={path}
                      isPreviewMode={isPreviewMode}
                    />
                  </div>
                </div>
                {
                  !isNotEmptyArray(lessons) && (
                    <p>We're sorry, but there are no lessons that match the filters you have selected.</p>
                  )
                }
              </React.Fragment>
            )
          }
        </div>
      </div>
    </div>
  );
};

DynamicLessonsList.propTypes = {
  isPreviewMode: PropTypes.bool,
};

export default DynamicLessonsList;
