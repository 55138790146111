import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SubNav from '../components/Layout/components/Navbar/components/SubNav';
import { specialsSubLinks } from '../constants/nav-links.const';
import Content, { HTMLContent } from '../components/Common/Content';
import PageTitle from '../components/Common/PageTitle';
import PropTypes from 'prop-types';
import DynamicLessonsList from '../components/Lessons/DynamicLessonsList';

export const LessonsListPageTemplate = ({ title, content, contentComponent, isPreviewMode }) => {
  const introComponent = contentComponent || Content;

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <PageTitle title={title} content={content} contentComponent={introComponent}/>
          </div>
        </div>
      </div>
      <DynamicLessonsList isPreviewMode={isPreviewMode}/>
    </section>
  );
};

LessonsListPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const LessonsListPage = ({data}) => {
  const title = data.markdownRemark.frontmatter.title;
  const content = data.markdownRemark.html;

  return (
    <Layout title="Lessons">
      <div className={`sub-nav-wrapper`}>
        <SubNav subLinks={specialsSubLinks}/>
      </div>
      <LessonsListPageTemplate title={title} content={content} contentComponent={HTMLContent} isPreviewMode={false}/>
    </Layout>
  );
}

LessonsListPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

export default LessonsListPage;

export const lessonsListQuery = graphql`
    query lessonsListQuery { 
        markdownRemark(frontmatter: { templateKey: { eq: "lessons-list-page" } }) {
            html
            frontmatter {
                title
            }
        }
    }
`
