import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import * as _ from 'lodash';
import LessonCard from './lesson-card/LessonCard';

const LessonsList = ({lessons, className}) => {
  return (
    <ul className={`list-unstyled lessons-list ${className}`}>
      {
        lessons && lessons.map((lesson) => {
          const path = `/lessons/${lesson.id}-${_.kebabCase(lesson.title)}`
          return (
            <li key={lesson.id} className="mb-3 pb-3 border-divider">
              <Link to={path} className="link-primary">
                <LessonCard lesson={lesson}/>
              </Link>
            </li>
          );
        })
      }
    </ul>
  );
};

LessonsList.propTypes = {
  lessons: PropTypes.array,
  className: PropTypes.string,
};

export default LessonsList;
